import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-22fad998"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "top-bar"
};
const _hoisted_2 = {
  class: "top-bar-left"
};
const _hoisted_3 = {
  class: "top-bar-right"
};
import { logout } from '@/api/user';
import { onMounted } from 'vue';

import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
export default {
  __name: 'TopBar',
  setup(__props) {
    const router = useRouter();
    const store = useStore();
    let userName = ref('');
    const curCurrency = computed(() => store.getters.curCurrency || '');
    const balanceWarningMsg = computed(() => store.state.user.balanceWarningInfo);
    const isWarning = computed(() => {
      const {
        warningLimit,
        availableBalance,
        warningFlag,
        chargeType
      } = balanceWarningMsg.value;
      return warningFlag && chargeType !== 0 && warningLimit && warningLimit > availableBalance;
    });
    const toggleNavCollapse = val => {
      store.commit('setNavCollapse', !val);
    };
    let dropdownVisible = ref(false);
    const toggleVisible = visible => {
      dropdownVisible.value = visible;
    };
    const accountBalanceConfirm = () => {
      accountBalanceRef.value.toggleDialog(false);
    };
    const accountBalanceRef = ref(null);
    const handleCommand = async command => {
      switch (command) {
        case 'accountPersonMsg':
          router.replace('/person/personMsg');
          break;
        case 'accountBalance':
          accountBalanceRef.value.toggleDialog(true);
          await getBalanceWarning();
          break;
        case 'logout':
          await logout().catch(() => {});
          router.replace('/login');
          break;
      }
    };
    const getBalanceWarning = async () => {
      store.dispatch('user/getBalanceWarningInfo');
    };
    onMounted(() => {
      userName.value = store.getters.userNickname;
    });
    return (_ctx, _cache) => {
      const _component_IEpExpand = _resolveComponent("IEpExpand");
      const _component_IEpFold = _resolveComponent("IEpFold");
      const _component_el_text = _resolveComponent("el-text");
      const _component_IEpArrowUp = _resolveComponent("IEpArrowUp");
      const _component_IEpArrowDown = _resolveComponent("IEpArrowDown");
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
      const _component_IEpSwitchButton = _resolveComponent("IEpSwitchButton");
      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
      const _component_el_dropdown = _resolveComponent("el-dropdown");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      const _component_CommonDialog = _resolveComponent("CommonDialog");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = $event => toggleNavCollapse(_ctx.$store.state.navCollapse))
      }, [_withDirectives(_createVNode(_component_IEpExpand, null, null, 512), [[_vShow, _ctx.$store.state.navCollapse]]), _withDirectives(_createVNode(_component_IEpFold, null, null, 512), [[_vShow, !_ctx.$store.state.navCollapse]])])]), _createElementVNode("span", _hoisted_3, [_createElementVNode("span", null, [_createTextVNode(_toDisplayString(_ctx.$t('user.accountBalance')) + ": ", 1), _createVNode(_component_el_text, {
        class: "account-balance",
        type: _unref(isWarning) ? 'danger' : ''
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(balanceWarningMsg).chargeType === 0 ? '-' : (_unref(balanceWarningMsg).availableBalance || '') + _unref(curCurrency)), 1)]),
        _: 1
      }, 8, ["type"])]), _createVNode(_component_el_dropdown, {
        class: "top-bar-dropdown",
        onVisibleChange: toggleVisible,
        onCommand: handleCommand
      }, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
            class: "dropdown-item",
            command: "accountBalance"
          }, {
            default: _withCtx(() => [_createVNode(_component_svg_icon, {
              class: "dropdown-item-icon alarm",
              "icon-class": "alarm"
            }), _createTextVNode(" " + _toDisplayString(_ctx.$t('user.accountBalanceMsg')), 1)]),
            _: 1
          }), _createVNode(_component_el_dropdown_item, {
            class: "dropdown-item",
            command: "logout"
          }, {
            default: _withCtx(() => [_createVNode(_component_IEpSwitchButton, {
              class: "dropdown-item-icon"
            }), _createTextVNode(" " + _toDisplayString(_ctx.$t('user.logout')), 1)]),
            _: 1
          })]),
          _: 1
        })]),
        default: _withCtx(() => [_createElementVNode("span", null, [_createTextVNode(_toDisplayString(_unref(userName)) + " ", 1), _withDirectives(_createVNode(_component_IEpArrowUp, null, null, 512), [[_vShow, _unref(dropdownVisible)]]), _withDirectives(_createVNode(_component_IEpArrowDown, null, null, 512), [[_vShow, !_unref(dropdownVisible)]])])]),
        _: 1
      })])]), _createVNode(_component_CommonDialog, {
        ref_key: "accountBalanceRef",
        ref: accountBalanceRef,
        title: _ctx.$t('user.accountBalanceMsg'),
        draggable: true,
        width: "500px",
        onDialogConfirm: accountBalanceConfirm
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          class: "account-balance-form"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            class: "",
            label: _ctx.$t('user.accountType')
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$store.getters.chargeType[_unref(balanceWarningMsg).chargeType]), 1)]),
            _: 1
          }, 8, ["label"]), _withDirectives(_createElementVNode("div", null, [_withDirectives(_createVNode(_component_el_form_item, {
            label: _ctx.$t('user.creditLimit')
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(balanceWarningMsg).creditLimit) + " " + _toDisplayString(_unref(curCurrency)), 1)]),
            _: 1
          }, 8, ["label"]), [[_vShow, _unref(balanceWarningMsg).chargeType === 2]]), _createVNode(_component_el_form_item, {
            label: `${_ctx.$t('user.accountBalance')} (${_ctx.$t('user.availableLimit')}):`
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(balanceWarningMsg).chargeType === 0 ? '-' : _unref(balanceWarningMsg).availableBalance + _unref(curCurrency)), 1)]),
            _: 1
          }, 8, ["label"]), _createVNode(_component_el_form_item, {
            label: _ctx.$t('user.warningAmount')
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(balanceWarningMsg).warningLimit) + " " + _toDisplayString(_unref(curCurrency)), 1)]),
            _: 1
          }, 8, ["label"]), _createVNode(_component_el_form_item, {
            label: _ctx.$t('user.warningCycle')
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$store.getters.warningIntervals[_unref(balanceWarningMsg).warningIntervals]), 1)]),
            _: 1
          }, 8, ["label"]), _createVNode(_component_el_form_item, {
            label: _ctx.$t('user.contactEmail')
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(balanceWarningMsg).warningEmail), 1)]),
            _: 1
          }, 8, ["label"])], 512), [[_vShow, _unref(balanceWarningMsg).chargeType !== 0]])]),
          _: 1
        })]),
        _: 1
      }, 8, ["title"])], 64);
    };
  }
};