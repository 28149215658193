import { createStore } from 'vuex';
import * as getters from './getters';
import * as actions from './actions';
import mutations from './mutations';
import { getCookie } from "@/utils/storage";
const modules = {};
const files = require.context('./modules', false, /\.js$/);
files.keys().forEach((key) => {
  modules[key.replace(/(modules|\/|\.|js)/g, '')] = {
    ...files(key).default,
    namespaced: true,
  };
});
export default createStore({
  state: {
    routesList: [],
    lang: '', // 当前语言
    allLang: [], // 可选语言列表
    accessToken: getCookie('atk') || '', // token
    refreshToken: getCookie('rfk') || '', // token
    navCollapse: getCookie('navCollapse') === 'true' || false,
    rememberPass: getCookie('remP') === 'true' || false,
    userNickname: getCookie('un') || '',
  },
  getters,
  mutations,
  actions,
  modules,
});
