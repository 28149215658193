import { alertTime } from '@/config';
import i18n from '@/i18n';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import store from '@/store';
/**
 * 弹出框显示
 * @param {String} content 提示内容
 * @param {String} title 提示标题
 * @param {String} type 提示类型，默认为 error
 * @param {Boolean} needCancel 是否需要取消按钮
 *  */
export function $message(content, title, type, needCancel, cancelButtonText, confirmButtonText) {
  const options = {
    title: title || $i18n('message.tips'),
    message: content,
    type: type || 'error',
    customClass: 'custom-message-box',
    showCancelButton: needCancel || false,
    cancelButtonText: cancelButtonText || $i18n('message.cancel'),
    confirmButtonText: confirmButtonText || $i18n('message.confirm'),
    closeOnClickModal: needCancel || false,
    closeOnPressEscape: needCancel || false,
    showClose: needCancel || false
  };
  return ElMessageBox(options);
}
export function $alert(msg, type, showClose, ops = {}) {
  const options = {
    showClose: showClose || true,
    message: msg,
    customClass: 'custom-alert',
    type: type || 'error',
    duration: alertTime || 3000,
    ...ops
  };
  ElMessage(options);
}
export function $notification(msg, title, type = 'info') {
  const options = {
    type: type,
    title: title || $i18n('message.tips'),
    message: msg || '',
    position: 'bottom-right'
  };
  return ElNotification(options);
}
export function $i18n(key, options, nullValue) {
  if (i18n.global.te(key)) return i18n.global.t(key, options);
  if (!nullValue) {
    return key;
  }
}
export function getRouteTitle(meta) {
  return meta && meta.title && $i18n(meta.title) || '';
}
// 检验字符串是不是md5
export function isMd5(inputString) {
  return /[a-fA-F0-9]{32}/.test(inputString);
}
export function timeFormat(time, str) {
  if (!time) {
    return '';
  }
  return moment(time).format(str || 'YYYY-MM-DD HH:mm:ss');
}
export function getUuid() {
  return uuidv4();
}
// 获取文字的宽度
export function getTextWidth(text = '', font = '') {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = font;
    const width = context.measureText(text).width;
    canvas.remove();
    return width;
  }
}
// 在中间位置打开窗口
export function openCenterNewWin(url, iWidth = 500, iHeight = 500) {
  if (!url) return;
  var iTop = (window.screen.height - 30 - iHeight) / 2;
  var iLeft = (window.screen.width - 10 - iWidth) / 2;
  return window.open(url, "_blank", `width=${iWidth},height=${iHeight},top = ${iTop}, left=${iLeft}`);
}
// 格式化金额
export function formatNum(num = 0) {
  if (typeof num !== 'number') {
    return '';
  }
  return num.toLocaleString('en-US', {
    maximumFractionDigits: 5
  });
}
export function fixedNum(num, fixedNum) {
  if (typeof num === 'number' && fixedNum) {
    return num.toFixed(fixedNum);
  }
  return num;
}
export function testPass(str) {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$/;
  if (regex.test(str)) {
    return '';
  } else {
    return $i18n('user.passWordError');
  }
}
// 是否有权限判断
export function hasLimit(limit) {
  // window.localStorage.getItem('permissions')
  let gloab_client_user = store.state.user.availablePerm;
  console.log('cyj-1105-sdf', limit);
  return gloab_client_user.indexOf(limit) > -1;
}