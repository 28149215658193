import "core-js/modules/es.array.push.js";
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8def695e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "nav-list"
};
import { computed } from 'vue';
import { getRouteTitle } from '@/utils';

import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
export default {
  __name: 'NavList',
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const menuList = computed(() => {
      return store.state.user.routeList.filter(routeItem => routeItem.meta && routeItem.meta.hidden !== true);
    });
    const activeMenu = computed(() => {
      return route.matched[1]?.path;
    });
    const toggleMenu = item => {
      router.push(item.index);
    };
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_el_sub_menu = _resolveComponent("el-sub-menu");
      const _component_el_menu = _resolveComponent("el-menu");
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      return _openBlock(), _createElementBlock("nav", _hoisted_1, [_createVNode(_component_el_scrollbar, null, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: _normalizeClass(["nav-logo", {
            collapse: _ctx.$store.state.navCollapse
          }])
        }, null, 2), _createVNode(_component_el_menu, {
          mode: "vertical",
          "collapse-transition": false,
          collapse: _ctx.$store.state.navCollapse,
          "default-openeds": ['0'],
          "default-active": activeMenu.value
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList.value, menu => {
            return _openBlock(), _createElementBlock(_Fragment, {
              key: menu.path
            }, [menu.children && (menu.children.length > 1 || menu.meta && menu.meta.subMenu) ? (_openBlock(), _createBlock(_component_el_sub_menu, {
              key: 0,
              index: menu.path
            }, {
              title: _withCtx(() => [menu.meta && menu.meta.icon ? (_openBlock(), _createBlock(_component_el_icon, {
                key: 0
              }, {
                default: _withCtx(() => [_createVNode(_component_svg_icon, {
                  "icon-class": menu.meta.icon,
                  class: "menu-item-icon"
                }, null, 8, ["icon-class"])]),
                _: 2
              }, 1024)) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(_unref(getRouteTitle)(menu.meta)), 1)]),
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.children, subMenu => {
                return _openBlock(), _createBlock(_component_el_menu_item, {
                  index: subMenu.path[0] === '/' ? subMenu.path : `${menu.path}/${subMenu.path}`,
                  key: subMenu.path,
                  onClick: toggleMenu
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(getRouteTitle)(subMenu.meta)), 1)]),
                  _: 2
                }, 1032, ["index"]);
              }), 128))]),
              _: 2
            }, 1032, ["index"])) : (_openBlock(), _createBlock(_component_el_menu_item, {
              key: 1,
              index: menu.path,
              onClick: toggleMenu
            }, {
              default: _withCtx(() => [menu.meta && menu.meta.icon ? (_openBlock(), _createBlock(_component_el_icon, {
                key: 0
              }, {
                default: _withCtx(() => [_createVNode(_component_svg_icon, {
                  "icon-class": menu.meta.icon,
                  class: "menu-item-icon"
                }, null, 8, ["icon-class"])]),
                _: 2
              }, 1024)) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(_unref(getRouteTitle)(menu.meta)), 1)]),
              _: 2
            }, 1032, ["index"]))], 64);
          }), 128))]),
          _: 1
        }, 8, ["collapse", "default-active"])]),
        _: 1
      })]);
    };
  }
};