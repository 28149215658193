import "core-js/modules/es.array.push.js";
import { apiTimeout } from '@/config';
import { $alert, $i18n } from '@/utils/index';
import { refreshToken } from '@/api/user';
import router from '@/router';
import store from '@/store';
import axios from 'axios';
const baseURL = '/api';
const getUtcOffset = () => {
  return -new Date().getTimezoneOffset() * 60;
};
let refreshTokening = false;
let waitRequests = [];
const loadingInstance = {
  loading: null,
  currentApiNum: 0,
  // 当前请求数量，只有0的时候可以关闭遮罩
  open() {
    this.currentApiNum++;
    this.loading = ElLoading.service({
      fullscreen: true,
      lock: true,
      text: '',
      spinner: 'none',
      customClass: 'api-fullscreen-loading'
    });
  },
  close() {
    this.currentApiNum--;
    console.log('当前正在进行请求数', this.currentApiNum);
    if (this.currentApiNum < 1) {
      this.loading && this.loading.close();
    }
  },
  clear() {
    this.currentApiNum = 0;
    this.loading && this.loading.close();
  }
};
export const instance = axios.create({
  baseURL,
  withCredentials: false,
  timeout: apiTimeout,
  // 请求超时时间毫秒
  headers: {
    // 设置后端需要的传参类型
    'Content-Type': 'application/json'
  }
});
const noSessionIdArr = ['/user/login'];
// 添加请求拦截器
instance.interceptors.request.use(function (requestData) {
  if (requestData.method === 'get') {
    requestData.params.lang = store.state.lang;
    requestData.params.utcOffset = getUtcOffset();
  } else {
    requestData.data.lang = store.state.lang;
    requestData.data.utcOffset = getUtcOffset();
    if (requestData.isFile) {
      // 创建一个formData对象
      const formData = new FormData();
      requestData.headers['Content-Type'] = `multipart/form-data; boundary=${formData._boundary}`;
      for (const key in requestData.data) {
        formData.append(key, requestData.data[key]);
      }
      requestData.data = formData;
    }
  }
  if (noSessionIdArr.indexOf(requestData.url) < 0) {
    requestData.headers.Authorization = store.state.accessToken;
  }
  return requestData;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});
async function tryRefreshToken(apiArgs) {
  try {
    // 不需要更新token
    if (apiArgs.noFreshToken) {
      throw 'error';
    }
    if (refreshTokening) {
      return new Promise(resolve => {
        waitRequests.push(() => {
          resolve(fetchByAxios({
            ...apiArgs,
            tokenRepeat: true
          }));
        });
      });
    } else {
      refreshTokening = true;
      const tokenRes = await refreshToken({
        refreshToken: store.state.refreshToken
      });
      if (tokenRes) {
        store.commit('setAccessToken', tokenRes.accessToken);
        store.commit('setRefreshToken', tokenRes.refreshToken);
        waitRequests.forEach(cb => cb());
        waitRequests = [];
        return fetchByAxios({
          ...apiArgs,
          tokenRepeat: true
        });
      } else {
        throw 'error';
      }
    }
  } catch (err) {
    waitRequests = [];
    router.replace({
      path: '/login',
      replace: true
    });
    loadingInstance.clear();
    return;
  } finally {
    refreshTokening = false;
  }
}
/**接口错误统一处理
 * @param data.code: 2001 接口调用成功；2002：参数校验失败；2003：接口调用失败；2004：没有权限访问资源；2005：token认证失败；2006：系统异常；2007用户未登录
 */
const hideCurLoading = hideLoading => {
  if (!hideLoading) {
    loadingInstance.close();
  }
};
const handleData = async ({
  data,
  status,
  message,
  statusText
}, apiArgs) => {
  if (status !== 200) {
    if (!apiArgs.hideError) {
      $alert(statusText || message);
    }
  } else {
    let message = data.message;
    switch (data.code) {
      case 2001:
        hideCurLoading(apiArgs.hideLoading);
        return Promise.resolve(data.data || true);
      case 2002:
        message = $i18n('message.apiError2002');
        break;
      case 2003:
        message = data.message || $i18n('message.apiError2003');
        break;
      case 2004:
        message = $i18n('message.apiError2004');
        break;
      case 2005:
        // token认证失败，重新获取token
        return await tryRefreshToken(apiArgs);
      case 2006:
        message = data.data || data.message;
        break;
      case 2007:
        await store.dispatch('resetAccount');
        loadingInstance.clear();
        router.replace('/login');
        return;
    }
    if (!apiArgs.hideError) {
      $alert($i18n(`message.${message || 'apiError2003'}`, {}, true) || message);
    }
  }
  hideCurLoading(apiArgs.hideLoading);
  return Promise.reject(data ? data.data : 'error');
};
export async function fetchByAxios({
  method,
  url,
  data = {},
  hideLoading,
  timeout,
  isFile,
  tokenRepeat
}) {
  const apiArgs = arguments[0];
  if (!hideLoading && !tokenRepeat) {
    loadingInstance.open();
  }
  const apiData = {
    method: method || 'post',
    url,
    isFile,
    timeout: timeout,
    params: method === 'get' ? data : {},
    data: method !== 'get' ? data : {}
  };
  try {
    const res = await instance(apiData);
    return handleData(res, apiArgs);
  } catch (err) {
    return handleData(err, apiArgs);
  }
}