/*! Element Plus v2.3.14 */

var mya = {
    name: "mya",
    el: {
      colorpicker: {
        confirm: "အိုကေ",
        clear: "ရှင်းလင်းသည်",
        defaultLabel: "အရောင်ရွေးချယ်မှု",
        description: "လက်ရှိအရောင်သည် {color} ဖြစ်သည်။ အရောင်အသစ်ကို ရွေးချယ်ရန် enter ကိုနှိပ်ပါ။"
      },
      datepicker: {
        now: "ယခု",
        today: "ယနေ့",
        cancel: "ပယ်ဖျက်သည်",
        clear: "ရှင်းလင်းသည်",
        confirm: "အိုကေ",
        dateTablePrompt: "မြှားခလုတ်များကိုသုံး၍ လ၏နေ့ရက်ကိုရွေးချယ်ရန်",
        monthTablePrompt: "မြှားခလုတ်များကိုသုံး၍ လကိုရွေးချယ်ရန်",
        yearTablePrompt: "မြှားခလုတ်များကိုသုံး၍ နှစ်ကိုရွေးချယ်ရန်",
        selectedDate: "ရွေးချယ်ထားသောရက်စွဲ",
        selectDate: "ရက်စွဲကို ရွေးပါ",
        selectTime: "အချိန်ရွေးပါ",
        startDate: "ရက်စွဲကို ရွေးပါ",
        startTime: "အချိန်ရွေးပါ",
        endDate: "ပြီးဆုံးရက်စွဲ",
        endTime: "ပြီးဆုံးချိန်",
        prevYear: "ယခင်နှစ်၊",
        nextYear: "နောက်နှစ်၊",
        prevMonth: "ယခင်လ",
        nextMonth: "နောက်လ",
        year: "",
        month1: "ဇန်န၀ါရီ",
        month2: "ဖေဖော်၀ါရီ",
        month3: "မတ်",
        month4: "ဧပြီ",
        month5: "မေ",
        month6: "ဇွန်",
        month7: "ဇူလိုင်",
        month8: "သြဂုတ်",
        month9: "စက်တင်ဘာ",
        month10: "အောက်တိုဘာ",
        month11: "နို၀င်ဘာ",
        month12: "ဒီဇင်ဘာ",
        week: "အပတ်",
        weeks: {
          sun: "တနင်္ဂနွေ",
          mon: "တနင်္လာနေ့",
          tue: "အင်္ဂါ",
          wed: "ဗုဒ္ဓဟူးနေ့",
          thu: "ကြာသပတေးနေ့",
          fri: "သောကြာ",
          sat: "စနေနေ့"
        },
        weeksFull: {
          sun: "တနင်္ဂနွေ",
          mon: "တနင်္လာနေ့",
          tue: "အင်္ဂါ",
          wed: "ဗုဒ္ဓဟူးနေ့",
          thu: "ကြာသပတေးနေ့",
          fri: "သောကြာ",
          sat: "စနေနေ့"
        },
        months: {
          jan: "ဇန်န၀ါရီ",
          feb: "ဖေဖော်၀ါရီ",
          mar: "မတ်",
          apr: "ဧပြီ",
          may: "မေ",
          jun: "ဇွန်",
          jul: "ဇူလိုင်",
          aug: "သြဂုတ်",
          sep: "စက်တင်ဘာ",
          oct: "အောက်တိုဘာ",
          nov: "နို၀င်ဘာ",
          dec: "ဒီဇင်ဘာ"
        }
      },
      inputNumber: {
        decrease: "အရေအတွက် လျော့ကျခြင်း၊",
        increase: "အရေအတွက်တိုး"
      },
      select: {
        loading: "လုပ်ဆောင်နေသည်",
        noMatch: "ကိုက်ညီသောဒေတာမရှိပါ",
        noData: "ဒေတာမရှိပါ",
        placeholder: "ရွေးချယ်ပါ"
      },
      dropdown: {
        toggleDropdown: "ဆွဲချခလုတ်ကို ပြောင်းရန်"
      },
      cascader: {
        noMatch: "ကိုက်ညီသောဒေတာမရှိပါ",
        loading: "လုပ်ဆောင်နေသည်",
        placeholder: "ရွေးချယ်ပါ",
        noData: "ဒေတာမရှိပါ"
      },
      pagination: {
        goto: "သွား",
        pagesize: "/စာမျက်နှာ,",
        total: "စုစုပေါင်း {total}",
        pageClassifier: "",
        page: "စာမျက်နှာ,",
        prev: "ယခင်စာမျက်နှာသို့သွားပါ",
        next: "နောက်စာမျက်နှာသို့သွားပါ",
        currentPage: "စာမျက်နှာ {pager}",
        prevPages: "ယခင် {pager} စာမျက်နှာများ,",
        nextPages: "နောက်ထပ် {pager} စာမျက်နှာများ,",
        deprecationWarning: "ကန့်ကွက်ထားသော အသုံးပြုမှုများကို တွေ့ရှိပါက၊ အသေးစိတ်အချက်အလက်များအတွက် el-pagination စာရွက်စာတမ်းကို ဖတ်ရှုပါ"
      },
      dialog: {
        close: "ဤဒိုင်ယာလော့ခ်ကို ပိတ်ပါ"
      },
      drawer: {
        close: "ဤဒိုင်ယာလော့ခ်ကို ပိတ်ပါ"
      },
      messagebox: {
        title: "မက်ဆေ့",
        confirm: "အိုကေ",
        cancel: "ပထ်ဖျက်ပါ",
        error: "တရားမဝင်ထည့်သွင်းမှု",
        close: "ဤဒိုင်ယာလော့ခ်ကို ပိတ်ပါ"
      },
      upload: {
        deleteTip: "ဖယ်ရှားရန် delete ကိုနှိပ်ပါ",
        delete: "ဖျက်သည်",
        preview: "အကြိုသုံးသပ်ချက်",
        continue: "ဆက်လုပ်မည်"
      },
      slider: {
        defaultLabel: "{min} နှင့် {max} ကြားရှိ ဆလိုက်ဒါ",
        defaultRangeStartLabel: "အစတန်ဖိုးကို ရွေးပါ၊",
        defaultRangeEndLabel: "အဆုံးတန်ဖိုးကို ရွေးပါ"
      },
      table: {
        emptyText: "ဒေတာမရှိပါ",
        confirmFilter: "အတည်ပြု",
        resetFilter: "ပြန်လည်သတ်မှတ်ခြင်း",
        clearFilter: "အားလုံး",
        sumText: "ပေါင်းခြင်း"
      },
      tree: {
        emptyText: "ဒေတာမရှိပါ"
      },
      transfer: {
        noMatch: "ကိုက်ညီသောဒေတာမရှိပါ",
        noData: "ဒေတာမရှိပါ",
        titles: ["စာရင်း 1", "စာရင်း 2"],
        filterPlaceholder: "သော့ချက်စာလုံးကို ရိုက်ထည့်ပါ၊",
        noCheckedFormat: "{total} ပစ္စည်းများ;",
        hasCheckedFormat: "{checked}/{total} စစ်ဆေးပြီး"
      },
      image: {
        error: "မအောင်မြင်ပါ"
      },
      pageHeader: {
        title: "နောက်သို့"
      },
      popconfirm: {
        confirmButtonText: "လုပ်မည်",
        cancelButtonText: "မလုပ်ပါ"
      }
    }
  };
  
  export { mya as default };
  