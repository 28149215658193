import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
import { ref } from 'vue';
export default {
  __name: 'CommonDialog',
  emits: ['dialogConfirm'],
  setup(__props, {
    expose: __expose,
    emit
  }) {
    let dialogVisible = ref(false);
    const toggleDialog = val => {
      dialogVisible.value = val === true;
    };
    const dialogConfirm = () => {
      emit('dialogConfirm');
    };
    __expose({
      toggleDialog
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: _unref(dialogVisible),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _isRef(dialogVisible) ? dialogVisible.value = $event : dialogVisible = $event),
        props: _ctx.$attrs,
        class: "common-dialog"
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          onClick: _cache[0] || (_cache[0] = $event => _isRef(dialogVisible) ? dialogVisible.value = false : dialogVisible = false)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('message.cancel')), 1)]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: dialogConfirm
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('message.confirm')), 1)]),
          _: 1
        })])]),
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 8, ["modelValue", "props"]);
    };
  }
};