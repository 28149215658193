import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-240d11c8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "page-title"
};
const _hoisted_2 = {
  key: 0,
  class: "current-title"
};
import { ref, watch, onBeforeMount } from 'vue';
import { getRouteTitle } from '@/utils';

import { useRoute } from 'vue-router';
export default {
  __name: 'PageTitle',
  setup(__props) {
    const route = useRoute();
    let currentPageName = ref('');
    let routeList = ref([]);
    const handleRouteTitle = routeV => {
      currentPageName.value = routeV.meta;
      routeList.value = routeV.matched.reduce((prev, cur, index) => {
        if (index < 2) {
          prev.push(cur.meta);
        }
        return prev;
      }, []);
    };
    watch(route, newV => {
      handleRouteTitle(newV);
    });
    onBeforeMount(() => {
      handleRouteTitle(route);
    });
    return (_ctx, _cache) => {
      const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");
      const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_breadcrumb, null, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(routeList), item => {
          return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
            key: item
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(getRouteTitle)(item)), 1)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      }), !(_unref(route).meta && _unref(route).meta.hideTitle) ? (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString(_unref(getRouteTitle)(_unref(currentPageName))), 1)) : _createCommentVNode("", true)]);
    };
  }
};