import Cookies from 'js-cookie';
export function setSessionStorage(key, value, flag) {
  window.sessionStorage.setItem(key, flag ? JSON.stringify(value) : value);
}

export function getSessionStorage(key, flag) {
  const sessionVal = window.sessionStorage.getItem(key) || null;
  return flag ? JSON.parse(sessionVal) : sessionVal;
}

export function removeSessionStorage(key) {
  return window.sessionStorage.removeItem(key);
}
export function setCookie() {
  Cookies.set(...arguments);
}
export function getCookie(key, flag) {
  const value = Cookies.get(key);
  return flag && value ? JSON.parse(value) : value;
}
export function removeCookie() {
  Cookies.remove(...arguments);
}

export function setLocalStorage(key, value, flag) {
  window.localStorage.setItem(key, flag ? JSON.stringify(value) : value);
}

export function getLocalStorage(key, flag) {
  const saveValue = window.localStorage.getItem(key) || null;
  return flag ? JSON.parse(saveValue) : saveValue;
}

export function removeUserLocalStorage(key) {
  window.localStorage.removeItem(key);
}
