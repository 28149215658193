import Layouts from '@/components/Layouts/Layouts';

const dynamicRouteList = [
    {
        path: '/overView',
        name: 'overView',
        component: Layouts,
        meta: {
          hidden: false,
          title: 'routes.overView',
          icon: 'overview',
          subMenu: true,
          limit: 'workbench'
        },
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                meta: {
                    hidden: false,
                    title: 'routes.dashboard',
                    limit: 'workbench'
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '@/views/overView/dashboard.vue')
            },
        ]
    },
    // 我的短信
    {
    path: '/mySms',
    name: 'mySms',
    component: Layouts,
    meta: {
        hidden: false,
        title: 'routes.mySms',
        icon: 'sms',
        subMenu: true,
        limit: "msg"
    },
    children: [
        {
            path: 'tabPage',
            name: 'TabPage',
            meta: {
                // hidden: false,
                title: 'routes.sendSms',
                limit: 'msg'
            },
            component: () => import('@/components/TabPage.vue'),
            children: [
                {
                    path: 'sendSms',
                    name: 'sendSms',
                    meta: {
                        title: 'mySms.sendSms',
                        limit: 'msg_single_send'
                    },
                    component: () => import(/* webpackChunkName: "sendSms" */ '@/views/mySms/sendSms/sendSms.vue'),
                },
                {
                    path: 'batchSms',
                    name: 'batchSms',
                    meta: {
                        title: 'mySms.batchSms',
                        limit: 'msg_multi_send'
                    },
                    component: () => import(/* webpackChunkName: "batchSms" */ '@/views/mySms/sendSms/batchSms.vue'),
                },
                {
                    path: 'templateSms',
                    name: 'templateSms',
                    meta: {
                        title: 'mySms.templateSms',
                        limit: 'msg_template_send'
                    },
                    component: () => import(/* webpackChunkName: "templateSms" */ '@/views/mySms/sendSms/templateSms.vue'),
                },
            ]
        },
        {
            path: 'smsTemplate',
            name: 'smsTemplate',
            meta: {
                hidden: false,
                title: 'routes.smsTemplate',
                limit: 'template'
            },
            component: () => import(/* webpackChunkName: "smsTemplate" */ '@/views/mySms/smsTemplate/smsTemplate.vue')
        },
    ]
    },
    // 报表
    {
        path: '/report',
        name: 'report',
        component: Layouts,
        meta: {
            hidden: false,
            title: 'routes.report',
            icon: 'report',
            subMenu: true,
            limit: 'report'
        },
        children: [
            {
                path: 'smsSendDetail',
                name: 'smsSendDetail',
                meta: {
                    hidden: false,
                    title: 'routes.smsSendDetail',
                    limit: 'sms_detail'
                },
                component: () => import(/* webpackChunkName: "smsSendDetail" */ '@/views/report/smsSendDetail.vue'),
            },
        ]
    },
    // 财务管理
    {
        path: '/financialManagement',
        name: 'financialManagement',
        component: Layouts,
        meta: {
            hidden: false,
            title: 'routes.financialMag',
            icon: 'financialManagement',
            subMenu: true,
            limit: 'finance'
        },
        children: [
            {
                path: 'rechargeCenter',
                name: 'rechargeCenter',
                meta: {
                    hidden: false,
                    hideTitle: true, // 隐藏右侧的页面名称
                    title: 'routes.rechargeCenter',
                    limit: 'finance_center',
                },
                component: () => import(/* webpackChunkName: "financialManagement" */ '@/views/financialManagement/rechargeCenter.vue'),
            },
            {
                path: 'orderHistory',
                name: 'orderHistory',
                meta: {
                    hidden: false,
                    title: 'routes.orderHistory',
                    limit: 'finance_order',
                },
                component: () => import(/* webpackChunkName: "financialManagement" */ '@/views/financialManagement/orderHistory.vue'),
            },
        ]
    },
    // 产品配置
    {
        path: '/product',
        name: 'product',
        component: Layouts,
        meta: {
            hidden: false,
            title: 'routes.product',
            icon: 'product',
            subMenu: true,
            limit: 'config'
        },
        children: [
            {
                path: 'apiDetail',
                name: 'apiDetail',
                meta: {
                    hidden: false,
                    title: 'routes.apiDetail',
                    limit: 'api_info'
                },
                component: () => import(/* webpackChunkName: "apiDetail" */ '@/views/product/apiDetail.vue'),
            },
        ]
    },
    // 系统管理
    {
        path: '/settings',
        name: 'settings',
        component: Layouts,
        meta: {
            hidden: false,
            title: 'routes.settings',
            icon: 'settings',
            subMenu: true,
            limit: 'system_settings',
        },
        children: [
            {
                path: 'childAccount',
                name: 'childAccount',
                meta: {
                    hidden: false,
                    title: 'routes.childAccount',
                    limit: 'system_settings_account'
                },
                component: () => import(/* webpackChunkName: "childAccount" */ '@/views/settings/childAccount.vue'),
            },
            {
                path: 'role',
                name: 'role',
                meta: {
                    hidden: false,
                    title: 'routes.role',
                    limit: 'system_settings_role'
                },
                component: () => import(/* webpackChunkName: "role" */ '@/views/settings/role.vue'),
            },
        ]
    }
];
export default dynamicRouteList;