import { createI18n } from 'vue-i18n';
import store from '@/store';
import { getCookie } from '@/utils/storage';
const modulesFiles = require.context('.', true, /\.json$/);
const reg = /lang\/(.*)\.json$/;
const allLanguage = [];
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.match(reg)[1] || '';
  const value = modulesFiles(modulePath);
  let eleLangItem = ''
  if (moduleName === 'mya') {
    eleLangItem = require(`/${moduleName}.mjs`);
  } else {
    eleLangItem = require(`element-plus/dist/locale/${moduleName}.mjs`);
  }
  const eleLangItemV = eleLangItem.default;
  if(value.eleLang) {
    for(let key in  eleLangItemV.el) {
      eleLangItemV.el[key] = Object.assign(eleLangItemV.el[key], value.eleLang[key] || {});
    }
  }
  value.eleLang = eleLangItemV;
  modules[moduleName] = value;
  const languageShow = value.languageShow;
  allLanguage.push({
    index: languageShow && languageShow.index || 99,
    text: languageShow && languageShow.text || moduleName,
    value: moduleName,
  });
  return modules;
}, {});
allLanguage.sort((x,y)=> x.index - y.index);
store.commit('setAllLang', allLanguage);
let currentLang = getCookie('lang');
if(!currentLang || !allLanguage.find(el=> el.value === currentLang)) {
  currentLang = allLanguage[0].value;
}
store.commit('setLang', currentLang);
const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  locale: currentLang,
  messages: modules
});

export default i18n;
