import { debounce } from 'lodash';

export default {
    beforeMount(el, binding) {
        const fn = binding.value; // 获取绑定的函数
        const debounceTime = binding.arg || 10000; // 获取防抖时间，默认为1000毫秒

        const debouncedFn = debounce(fn, debounceTime); // 创建防抖函数

        // 绑定相应的事件
        el.addEventListener('click', debouncedFn);
    }
};