import { setCookie, removeCookie } from "@/utils/storage";
export default {
  setRoutesList(state, data) {
    state.routesList = data || [];
  },
  setLang(state, data) {
    state.lang = data;
    setCookie('lang', data);
  },
  setAllLang(state, data) {
    state.allLang = data || {};
  },
  setSession(state, data) {
    state.token = data;
    setCookie('pa', data);
  },
  setNavCollapse(state, data) {
    state.navCollapse = data;
    setCookie('navCollapse', data);
  },
  setAccessToken(state, data) {
    state.accessToken = data || '';
    if (!data) {
      removeCookie('atk');
    } else {
      setCookie('atk', data || '');
    }
  },
  setRefreshToken(state, data) {
    state.refreshToken = data || '';
    if (!data) {
      removeCookie('rfk');
    } else {
      setCookie('rfk', data || '');
    }
  },
  setRememberPass(state, data) {
    const remPass = data || false;
    state.rememberPass = remPass;
    setCookie('remP', remPass);
  },
  setUserNickname(state, data) {
    state.userNickname = data;
    setCookie('un', data);
  },
};