import store from '@/store';
import router, { resetRouter, constantRoutes } from '@/router';
import dynamicRouteList from '@/router/dynamicRouteList';
import { $alert, $i18n } from '@/utils/index';
const whitePath = ['/login', '/register', '/platformAgreement' ,'/bilateralAgreement', '/viewPdf', '/viewPdfEn', '/errorCode'];
let isFirstIn = true;
function handleLimit(limits, routes = []) {
    return routes.filter(el => {
        const limit = el.meta?.limit;
        if (el.children) {
            el.children = handleLimit(limits, el.children);
        }
        return !limit || limits[limit];
    });
}

function handleLimits(limits, router = []) {
    let route = []
    router.map(mp => {
        if ( mp.meta?.limit && limits.indexOf(mp.meta.limit) > -1) {
            if (Object.hasOwnProperty.call(mp, 'children')) {
                let dChild = mp.children
                let dList = []
                if (dChild && dChild.length > 0) {
                    dChild.map(dp => {
                        if (dp.meta?.limit && limits.indexOf(dp.meta.limit) > -1) {
                            if (Object.hasOwnProperty.call(dp, 'children')) {
                                let dpChild = dp.children
                                let dpList = []
                                if (dpChild && dpChild.length > 0) {
                                    dpChild.map(pm => {
                                        if (pm.meta?.limit && limits.indexOf(pm.meta.limit) > -1) {
                                            dpList.push(pm)
                                        }
                                    })
                                }
                                if (dpList.length) {
                                    dp.children = dpList
                                    dList.push(dp)
                                }
                            } else {
                                dList.push(dp)
                            }
                        }
                    })
                }
                if (dList.length) {
                    mp.children = dList
                    route.push(mp)
                }
            } else {
                route.push(mp)
            }
        }
    })
    console.log('cyj-1107-route', route)
    return route
}

let hasAccessToken = false;
function initRouter() {
    resetRouter();
    hasAccessToken = store.state.accessToken;
    isFirstIn = true;
    store.dispatch('resetAccount');
}
router.beforeEach(async (to, from, next) => {
    console.log('cyj-1106-to',to)
    if(to.path === '/login') {
        initRouter();
    }
    if (!whitePath.includes(to.path)) {
        if (!store.state.accessToken) {
            next({ path: '/login', replace: true });
            return;
        }
        // 获取系统设置
        if (isFirstIn) {
            isFirstIn = false;
            await store.dispatch('user/getBalanceWarningInfo', true);
            const availablePerm = store.state.user.availablePerm;
            let newRoutesMenu = handleLimits(availablePerm, constantRoutes);
            if (to.meta?.limit) {
                if (availablePerm.length && availablePerm.indexOf(to.meta.limit) < 0) {
                    if (newRoutesMenu.length) {
                        store.commit('user/setRouteList', newRoutesMenu);
                        let menu = newRoutesMenu[0]
                        let path = menu.path
                        if (Object.hasOwnProperty.call(menu, 'children')) { 
                            let c_menu = menu.children[0]
                            path = path + '/' + c_menu.path
                            if (Object.hasOwnProperty.call(c_menu, 'children')) { 
                                let d_menu = c_menu.children[0]
                                path = path + '/' + d_menu.path
                            }
                        }
                        next({ path: path , replace: true });
                    } else {
                        $alert($i18n('message.noAuth'), 'error');
                        next({ path: '/login', replace: true })
                    }
                    return
                }
            }
            store.commit('user/setRouteList', newRoutesMenu);
            // newRoutesMenu.map(el => router.addRoute(el));
            next({ ...to, replace: true });
            return;
        } else {
            if (to.path === "/mySms/tabPage") {
                const availablePerm = store.state.user.availablePerm;
                if (availablePerm.length && availablePerm.indexOf('msg_single_send')>-1) {
                    next({ path: to.path + '/sendSms', replace: true })
                    return
                }
                else if (availablePerm.length && availablePerm.indexOf('msg_multi_send')>-1) {
                    next({ path: to.path + '/batchSms', replace: true })
                    return
                }
                else if (availablePerm.length && availablePerm.indexOf('msg_template_send')>-1) {
                    next({ path: to.path + '/templateSms', replace: true })
                    return
                }
            }
        }
    }
    next();
});
router.afterEach(async(to) => {
    if(to === '/login' && hasAccessToken) {
        window.location.reload();
    }
});
