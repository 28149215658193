import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed, toRaw } from 'vue';
import { ElConfigProvider } from 'element-plus';
import { useStore } from 'vuex';
import i18n from '@/i18n';
export default {
  __name: 'App',
  setup(__props) {
    const $store = useStore();
    const eleLocale = computed(() => {
      return toRaw(i18n.global.messages.value[$store.state.lang]['eleLang']);
    });
    function debounce(callback, delay) {
      let tid;
      return function () {
        clearTimeout(tid);
        tid = setTimeout(() => {
          callback.apply(this, arguments);
        }, delay);
      };
    }
    window.ResizeObserver = class ResizeObserver extends window.ResizeObserver {
      constructor(callback) {
        callback = debounce(callback, 20);
        super(callback);
      }
    };
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_unref(ElConfigProvider), {
        size: "default",
        locale: eleLocale.value
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      }, 8, ["locale"]);
    };
  }
};