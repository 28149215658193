module.exports= {
    // 报错页面自动跳转时间
    errorJumpTime: 10,
    // 是否显示全屏切换按钮
    showFullscreen: true,
    // 网页title
    title: "Intelligent SMS Platform",
    // 接口超时时间
    apiTimeout: 15000,
    // 错误或者成功提示显示时间
    alertTime: 2000,
    // 
    titleLogo: ''
};