import { getBalanceWarningInfo, getSysConfigList } from '@/api/user';
const state = () => ({
  sysConfigList: {
    enablePayPal: false,
    // 在线充值方式PalPal是否可用
    EnableStripe: false // 在线充值方式Stripe是否可用
  },

  // 系统设置
  curCurrency: '',
  // 当前用户的货币类型
  balanceWarningInfo: {},
  // 余额信息
  minRechargeAmount: 10,
  //最低充值金额
  paymentChannels: {
    // 支付方式
    10: {
      label: 'PayPal',
      // 显示文字
      img: require('@/assets/payment/Paypal.jpeg'),
      // 显示图片
      tip: 'financialMag.payByPaypalTip',
      // 说明
      limit: 'enablePayPal' // 权限
    },

    20: {
      label: 'Stripe',
      img: require('@/assets/payment/Stripe.png'),
      tip: 'financialMag.payByStripeTip',
      limit: 'enableStripe'
    }
  },
  paymentStatus: {
    // 支付状态
    '0': {
      label: 'financialMag.notPaid',
      class: 'not-paid'
    },
    '1': {
      label: 'financialMag.havePaid',
      class: 'success'
    },
    '2': {
      label: 'financialMag.payTimeout',
      class: 'timeout'
    },
    '3': {
      label: 'financialMag.payFail',
      class: 'fail'
    },
    '4': {
      label: 'financialMag.duringPayment',
      class: 'paying'
    }
  },
  dynamicRouteLimits: {},
  // 动态路由权限
  availablePerm: [],
  routeList: [],
  // 路由列表，用于显示菜单
  stripePublishableKey: "pk_test_51Ord8zHfM4Rgz25rbTxC4usSD8BJd3FaEWsbGULwLthm9qyI5BUwrjcuV91c8Bx3qabUWN5gsL2JZohRAdba6a2z00KM2MJ4tk" // 用户stripe支付
});

const getters = {};
const mutations = {
  setCurCurrency(state, data) {
    state.curCurrency = data;
  },
  setBalanceWarningMsg(state, data) {
    state.balanceWarningInfo = data || {};
  },
  setSysConfigList(state, data) {
    state.sysConfigList = data || {};
  },
  setDynamicRouteLimits(state, data) {
    state.dynamicRouteLimits = data || {};
  },
  setAvailablePerm(state, data) {
    state.availablePerm = data || [];
  },
  setRouteList(state, data) {
    state.routeList = data || [];
  }
};
const actions = {
  getBalanceWarningInfo: async ({
    commit
  }, showLoading) => {
    let loading;
    if (showLoading) {
      loading = ElLoading.service({
        fullscreen: true,
        lock: true
      });
    }
    const warningMsg = (await getBalanceWarningInfo().catch(() => {})) || {};
    showLoading && loading && loading.close();
    commit('setCurCurrency', warningMsg.currency || '');
    commit('setBalanceWarningMsg', warningMsg);
    commit('setDynamicRouteLimits', warningMsg.enable);
    commit('setAvailablePerm', warningMsg.availablePerm);
  },
  getSysConfigList: async ({
    commit
  }) => {
    const res = await getSysConfigList().catch(() => {});
    commit('setSysConfigList', res);
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};