import { fetchByAxios } from '@/utils/request';
/**
   * 登录
   * @param {Object} data
   * @param {String} data.userName 登录名
   * @param {String} data.password 密码
   */
export function login(data) {
  return fetchByAxios(
    {
      url: '/user/login',
      method: 'post',
      data
    });
}
/**
   * 退出登录
   */
 export function logout() {
  return fetchByAxios(
    {
      url: '/user/logout',
      method: 'get',
      data: {}
    });
}
/**
   * 更新token
   * @param {Object} data
   * @param {String} data.refreshToken 
   */
 export function refreshToken(data) {
  return fetchByAxios(
    {
      noFreshToken:true,
      url: '/user/refreshToken',
      method: 'post',
      data,
      hideLoading: true,
      hideError: true
    });
}
/**
   * 注册
   * @param {Object} data
   */
 export function userRegister(data) {
  return fetchByAxios(
    {
      noFreshToken:true,
      url: '/enterprise/regist',
      method: 'post',
      data,
    });
}
/**
   * 客户余额预警信息
   */
 export function getBalanceWarningInfo() {
  return fetchByAxios(
    {
      url: '/enterprise/getBalanceWarningInfo',
      method: 'get',
      data: {},
    });
}
/**
   * 客户余额预警信息修改
   * @param {Object} data
   * @param {Number} data.warningFlag 是否开启余额预警
   * @param {Number} data.warningLimit 预警金额
   * @param {Number} data.warningIntervals 预警周期
   * @param {String} data.warningEmail 联系邮箱
   */
export function updateBalanceWarning(data) {
  return fetchByAxios(
    {
      url: '/enterprise/updateBalanceWarning',
      method: 'post',
      data,
    });
}

/**
   * 获取系统配置数据
   * @param {Object} data
   */
 export function getSysConfigList(data) {
  return fetchByAxios(
    {
      url: '/sys/getSysConfigList',
      method: 'get',
      data
    });
}