import { createRouter, createWebHistory } from 'vue-router';
import { cloneDeep } from 'lodash';
import Layouts from '@/components/Layouts/Layouts';
import dynamicRouteList from './dynamicRouteList'

/** meta说明
 * @param hidden 在菜单栏是否隐藏
 * @param title 菜单名称i18n的key
 * @param icon 图标名称
 * @param subMenu 是否有子菜单
 * @param hideTitle 是否隐藏详情页大写的标题
 */
export const constantRoutes = [
  {
    path: '/',
    name: 'home',
    redirect: '/overView/dashboard',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '@/views/register.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/platformAgreement',
    name: 'platformAgreement',
    component: () => import(/* webpackChunkName: "platformAgreement" */ '@/views/serviceTerms/platformAgreement.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/bilateralAgreement',
    name: 'bilateralAgreement',
    component: () => import(/* webpackChunkName: "bilateralAgreement" */ '@/views/serviceTerms/bilateralAgreement.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/viewPdf',
    name: 'viewPdf',
    component: () => import(/* webpackChunkName: "viewPdf" */ '@/views/serviceTerms/viewPdf.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/viewPdfEn',
    name: 'viewPdfEn',
    component: () => import(/* webpackChunkName: "viewPdfEn" */ '@/views/serviceTerms/viewPdfEn.vue'),
    meta: {
      hidden: true,
    },
  }, 
  {
    path: '/errorCode',
    name: 'errorCode',
    component: () => import(/* webpackChunkName: "errorCode" */ '@/views/serviceTerms/errorCode.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/error/403.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error/404.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/person',
    name: 'person',
    component: Layouts,
    meta: {
      hidden: true,
    },
    children: [
      {
        path: 'personMsg',
        name: 'personMsg',
        meta: {
            hidden: true,
            title: 'routes.personMsg'
        },
        component: () => import(/* webpackChunkName: "personMsg" */ '@/views/person/personMsg.vue')
      },
    ]
  },
  ...dynamicRouteList,
  {
    path: '/:pathMatch(.*)',
    name: 'notFound',
    redirect: '/404',
}
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRoutes,
});
function fatteningRoutes(routes) {
  return routes.flatMap((route) => {
    return route.children ? [route, ...fatteningRoutes(route.children)] : route;
  });
}

export function resetRouter() {
  const routeList = fatteningRoutes(cloneDeep(constantRoutes));
  const routeNameList = routeList.map(el => el.name);
  router.getRoutes().forEach((route) => {
    const routeName = route.name;
    if(!routeNameList.includes(routeName) && router.hasRoute(routeName)&& routeName !== 'notFound'){
      router.removeRoute(routeName);
    }
  });
}
// 动态添加路由  
export default router;
